.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card {
  width: 45%;
  min-height: 100px;
  border: solid black 1px;
  border-radius: 20px;
  background-color: aliceblue;
  padding:20px;
  margin-bottom: 20px;
}
.cardHeader {
  display: flex;
  justify-content: space-between;
}
.cardName {
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%
}
.bio {
  margin-top: 10px;
  margin-bottom: 10px;
}
.profileImage {
  max-height: 250px;
  max-width: 100%;
  border-radius: 25%;
}
.editContainer {
  justify-self: right;
}
