.icon {
  color: dodgerblue;
}
.icon:hover,
.icon:focus {
  cursor: pointer;
  color: blueviolet;
}

.text {
  white-space: pre-wrap;
}
