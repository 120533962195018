/* .uploaded-files-input {
  width: 10em;
} */

.filter-bar {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
}

.filter {
  z-index: -1;
}
